import { SINGLE_CTA_EXPERIMENT } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useSingleCTAExperiment = () => {
  const { TREATMENT_1, TREATMENT_2, CONTROL } = SINGLE_CTA_EXPERIMENT.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const isTreatment1 = isEnabled && variation === TREATMENT_1
  const isTreatment2 = isEnabled && variation === TREATMENT_2

  const isTreatment = isEnabled && (isTreatment1 || isTreatment2)

  return {
    isLoading,
    isTreatment1,
    isTreatment2,
    isTreatment,
    textConstant: {
      ctaLabel: 'Start applying',
    },
  }
}
